<template>
    <CardComponent class="train-card" :title="title" :icon="icon" :link="link">
        <section>
            <Train :show="false" />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import Train from '../../profile/component/Training'

export default {
    name: 'TrainCard',
    components: { CardComponent, Train },
    data() {
        return {
            title: '我的培训',
            icon: 'iconfont icon-wodepeixun',
            link: '/profile/score'
        }
    },
    created() {},
    methods: {}
}
</script>
<style lang="less" scoped>
.train-card {
    grid-column-start: span 2;
    grid-row-start: span 2;
}
</style>
